import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  SelectionState, IntegratedSelection, IntegratedSorting, SortingState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, Table,
  TableHeaderRow, PagingPanel, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, MENSAJE_GENERAL
} from 'utils/Constants';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';  
//import { s2ab } from 'utils/Functions'; 
import excelPng from "images/icons/excel.png"; 
import { s2ab } from 'utils/Functions';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer"; 

import AlmacenRepuestoDialog from "components/Dialog/AlmacenRepuestoDialog";

import AlmacenRepuestoDialogMnt from "components/Dialog/AlmacenRepuestoDialogMnt"; 

import AutorenewIcon from '@material-ui/icons/Autorenew';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
const getRowId = row => row.id;

class BsqDomAlmacenRepuestoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGuadar: 'flex',
      columns: [
         { name: 'id', title: 'Id' },
        { name: 'codigo', title: 'Código' },
        { name: 'codreemplazo', title: 'Cod. Reemp.' }, 
        { name: 'imagen', title: 'Imagen' },
        { name: 'descripcion', title: 'Descripción ' },
        { name: 'artefacto', title: 'Artefacto' },
        { name: 'marca', title: 'Marca' },  
        { name: 'modelo', title: 'Modelo' },
        { name: 'sede', title: 'Sede' },  
        { name: 'stock', title: 'Stock' },   
        { name: 'ubicacion', title: 'Ubicación' },
        { name: 'precio', title: 'Precio' },
        { name: 'archivo', title: 'Acción' }
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },
        { columnName: 'fechaatencion', width: 100, wordWrapEnabled: true },
        { columnName: 'codproducto', width: 120, wordWrapEnabled: true },
        { columnName: 'modelo', width: 120, wordWrapEnabled: true },
        { columnName: 'marca', width: 100 }, 
        { columnName: 'estadoname', width: 150 }, 
        { columnName: 'ttrabajo', width: 120 },  
        { columnName: 'distrito', width: 120 },  
        { columnName: 'importe', width: 100 },
        { columnName: 'nombre', width: 100, wordWrapEnabled: true },
        { columnName: 'tecnico', width: 140, wordWrapEnabled: true },
        { columnName: 'hora', width: 100, wordWrapEnabled: true },
        { columnName: 'archivo', width: 200 },
      ],
      columnsSeguimiento: [
        { name: 'fecha_seguimiento', title: ' Fecha' },
        { name: 'cdr_code', title: 'Código ' },
        //{ name: 'estado_seguimiento', title: 'Estado' },
        { name: 'cdr_nota', title: 'Nota' },
        { name: 'cdr_observacion', title: 'Observación' }
      ],
      tableColumnSeguimientoExtensions: [
        { columnName: 'fecha_seguimiento', width: 120, wordWrapEnabled: true },
        { columnName: 'cdr_code', width: 80, wordWrapEnabled: true },
        //{ columnName: 'estado_seguimiento', width: 100, wordWrapEnabled: true },
        { columnName: 'cdr_nota', width: 250, wordWrapEnabled: true },
        { columnName: 'cdr_observacion', width: 250, wordWrapEnabled: true }
      ],
      headersCsv: [{
        id: 'id',
        display: 'id'
      }, {
        id: 'fecha_seguimiento',
        display: 'Fecha'
      }, {
        id: 'cdr_code',
        display: 'Codigo'
      },/* {
        id: 'estado_seguimiento',
        display: 'Estado'
      },*/ {
        id: 'cdr_nota',
        display: 'Nota'
      }, {
        id: 'cdr_observacion',
        display: 'Observacion'
      }],
      rows: [],
      //sorting: [{ columnName: 'docu_fecha', direction: 'asc' }],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      showSeguimientoDialog: false,
      seguimientoRows: [],
      selection: [],
      showUsuarioDialog: false,
      showRepuestoDialogMnt: false,
      repuestos: [],
      documento:{}
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeSelection = selection => {
      const { show } = this.props.notificationActions;
      // console.log(selection);
      //console.log(selection.length);
      if (selection.length > 250){
        show("1", "La cantidad de archivos a descargar debe ser menor a 250", TIPO_MENSAJE.WARNING);
        //console.log("error la cantidad de archivos a descargar debe ser menor a 250");
      }
      this.setState({ selection: selection });
    }
    //this.changeCurrentPage = currentPage => this.setState({ currentPage });
    //this.changePageSize = pageSize => this.setState({ pageSize });

  }

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarDocumentos(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarDocumentos(currentPage, pageSize);
  }

  componentDidMount() {
    
    this.buscarDocumentos();
    this.state.selection = [];
    this.props.onRef(this);
     
    if (localStorage.getItem("tkn") == "serfac-tecnicos"){
      this.state.showGuadar = 'None'
    }
    //this.buscarDocumentos();
  }

  limpiarForm = () => {
    //this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: '',
      clieNumero: '',  //es ruc
      docuFechaIni: '',
      docuFechaFin: '',
      estado: '',
      clieNombre: '', //razon Social
      cdr: ''
    };
    
    this.setState({ rows: [] });
    this.setState({ documento: documento });
  }

  buscarDocumentos(page, size) {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    //validamos filtros
     
    let {documento} = this.props;
    documento.operacion = "listarAlmacenRepuesto";
    documento.tiporegistro = "D";
    console.log("documentoPPPPPP:::", documento);
    if (localStorage.getItem("tkn") == "serfac-ilumi"){ 
      documento.area = 15; // siempre para ilumi 15
    }

    if (localStorage.getItem("tkn") == "serfac-indurama"){ 
      documento.area = documento.area ? documento.area : "18,19"; // 
    }
    

    this.props.actions.consultarFichas(documento, page + 1, size).then(response => { 
      if (response.status === 200) {
        this.setState({ rows: response.data, totalCount: response.totalCount })
      } else {
        if (response.data != null && response.data == 'dato invalido'){
          show(response.indMostrarMensaje, "Ingrese información valida para la busqueda", TIPO_MENSAJE.WARNING);
        }else{
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
      }
      remove();
    })
  }

  

  Cell = (props) => {
    const { column, row } = props; 

    if (column.name === 'archivo' ) {
      return (
        <TableCell style={{ padding: 0 }}>
           <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuestoMnt.bind(this, row)} title="Editar Repuesto" >
            <EditIcon> </EditIcon>
          </IconButton>  
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuesto.bind(this, row)} title="Kardex" >
            <LibraryBooksIcon>{row.cdr}</LibraryBooksIcon>
          </IconButton> 
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuesto.bind(this, row)} title="Ingresos/Salidas" >
            <AutorenewIcon> </AutorenewIcon>
          </IconButton> 
         
        </TableCell>
      );
    }
    if (column.name === 'imagen' ) {
      return (
        <TableCell style={{ padding: 0 }}>
          <img width="60" height="60" src={row.imagen}/>
        </TableCell>
      );
    }

    return <VirtualTable.Cell {...props} />;
  };

  

  toggleDialogRepuesto = (documento) => {
    console.log("valor seleccionado" , documento)

    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    if (documento  != null){
      add();
      
          this.setState({ documento: documento}) 
          this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
         
        remove(); 
    }else{
      this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
    } 
  };


  toggleDialogRepuestoMnt = (documento) => {
    
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    if (documento  != null){
      add();
      console.log("valor seleccionado" , documento)

          this.setState({ documento: documento}) 
          this.setState({ showRepuestoDialogMnt: !this.state.showRepuestoDialogMnt })
         
        remove(); 
    }else{
      this.setState({ showRepuestoDialogMnt: !this.state.showRepuestoDialogMnt })
    } 
  };


  exportarExcel = () => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    var fechaEnMiliseg;
    const { totalCount } = this.state;
    var d = new Date();
    fechaEnMiliseg =  ("00" + (d.getMonth() + 1)).slice(-2) + "-" + 
      ("00" + d.getDate()).slice(-2) + "-" + 
      d.getFullYear() + "_" + 
      ("00" + d.getHours()).slice(-2) + "" + 
      ("00" + d.getMinutes()).slice(-2) + "" + 
      ("00" + d.getSeconds()).slice(-2);
    const { documento } = this.props;
    //if (documento.clieNumero == "" && documento.clieNombre == ""){
      //show("1", "debe filtrar por los datos de un cliente para esta opción", TIPO_MENSAJE.WARNING);
    //}else{
      //console.log("usuario:::", this.props.user);
      add();
      if (totalCount > 0 ){
        this.props.actions.descargarExcel(documento, this.props.user, totalCount).then(response => {
          if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            if (totalCount <= 3000){
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              var json = response.data,
                blob = new Blob([s2ab(atob(json))], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = "artefactos-" + fechaEnMiliseg + ".xlsx";
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
              show(response.indMostrarMensaje, response.mensaje, response.tipo);
            }else{
              show(MENSAJE.IND_MOSTRAR_MENSAJE, "Se le enviara el excel a su correo electronico en unos minutos.", TIPO_MENSAJE.SUCCESS);
            }
          }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
          }
          remove();
        })
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "No existen registros a exportar, consulte la información antes de exportar.", TIPO_MENSAJE.WARNING);
        remove();
      }
  }
 


  toggleDialogRepuestoRegistro = (currentPage, page) => {
     this.buscarDocumentos(currentPage, page);
     this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog }) 
  };
  toggleDialogRepuestoRegistroMnt = (currentPage, page) => {
    this.buscarDocumentos(currentPage, page);
    this.setState({ showRepuestoDialogMnt: !this.state.showRepuestoDialogMnt }) 
 }; 

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions, 
      sorting,
      selection,
      currentPage,
      pageSize,
      pageSizes,
      totalCount, 
      showRepuestoDialog,
      showRepuestoDialogMnt,
      repuestos,
      documento,
      showGuadar
    } = this.state;

    const {  classes } = this.props;

    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          <IconButton color="primary" aria-label="exportar a excel" style={{ display: showGuadar, float: 'right' }} onClick={this.exportarExcel} title="Solo se exportan los primeros 3000 resultados a excel" >
            <img src={excelPng} height="38" width="38" />
          </IconButton>
          <IconButton color="primary" aria-label="Descargar Rut" style={{  float: 'right' }} onClick={this.exportarPdf} title="Descargar Ruta" >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" /></svg>
          
          </IconButton>
        </GridItem>
      <div style={{ display: "none" }}> <img id='itf' /></div>
        <GridItem xs={12} sm={12} md={12}>
           
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "100%" }}
              getRowId={getRowId}
              className={"ps-child"}
            >

              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <SelectionState
                selection={selection}
                onSelectionChange={this.changeSelection}
              />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <CustomPaging
                totalCount={totalCount}
              />
              {}
              {}
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />
              <IntegratedSelection />
              <TableSelection showSelectAll />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
            </Grid>
            <AlmacenRepuestoDialog currentPage={currentPage} pageSize={pageSize} toggleDialogRepuesto={this.toggleDialogRepuesto} toggleDialogRepuestoRegistro={this.toggleDialogRepuestoRegistro} showRepuestoDialog={showRepuestoDialog} classes={classes} repuestos={repuestos} documento={documento}
               />

          <AlmacenRepuestoDialogMnt currentPage={currentPage} pageSize={pageSize} toggleDialogRepuestoMnt={this.toggleDialogRepuestoMnt}   toggleDialogRepuestoRegistroMnt={this.toggleDialogRepuestoRegistroMnt}  showRepuestoDialogMnt={showRepuestoDialogMnt} classes={classes}  documento={documento}  />
          </Paper>
        </GridItem>
      </GridContainer>

    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(BsqDomAlmacenRepuestoGrid));