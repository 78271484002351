/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/SidebarMenu";
import homeStyle from "styles/jss/app/containers/homeStyle";
import image from "images/banner-mob.png";
import logo from "images/logo-rimac-r.png";
import logoserfac from "images/logo-serfac-sistema.png";
import logoMini from "images/icon-serfac.png";
import classNames from "classnames";

import * as notificationActions from "actions/NotificationActions";
import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import * as opcionActions from "actions/OpcionActions";
import Alert from "components/Snackbar/SnackbarAlert";
import Loader from "components/Loader/Loader";
import { TIPO_MENSAJE } from 'utils/Constants';
import { VIEW_COMPONENT } from "commons/config/Views";
import {isMobile} from 'react-device-detect';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      open: true,
      opciones: []
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    var psSidebar;
    var psMain;
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  handleMiniDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  handleItemListCollapse = (name) => {
    this.setState({ [name]: !this.state[name] });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    if ((!isMobile) ) {
      this.psMain = new PerfectScrollbar(this.refs.mainPanel);
      this.psSidebar = new PerfectScrollbar("#sidebarWrapperWeb");
    }
    console.log("entro al home");
    console.log("usuario::::::::::::::",this.props.user);
    window.addEventListener("resize", this.resizeFunction);
    //this.buscarOpciones();
  }
  componentDidUpdate(e) {
    if (!isMobile) {
      this.psMain.update();
      this.psSidebar.update();
    }
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  closeNotification = (event, reason) => {
    if (typeof reason === "undefined" || reason !== "clickaway") {
      const { close } = this.props.notificationActions;
      close(false)
    }
  };
  async buscarOpciones() {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
      let response = {
        "data": [
          {
            "IDEOPCION": 10,
            "NOMOPCION": "Nuevo Servicio",
            "DSCOPCION": "Opción padre para Registros",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/registro",
            "DSCVISTA": "Consulta",
            "DSCICONO": "business_center",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 11,
                "NOMOPCION": "A Domicilio",
                "DSCOPCION": "Opción para realizar registro de artefactos",
                "DSCTITOPCION": "Registro de Artefactos de Domicilio",
                "DSCRUTA": "/registro/domicilio",
                "DSCVISTA": "RegistroDomicilio",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 10,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 17,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar registro de artefactos",
                "DSCTITOPCION": "Registro de Artefactos de Taller",
                "DSCRUTA": "/documento/registro",
                "DSCVISTA": "ConsultarDocumento",
                "DSCICONO": "notes",
                "IDEOPCIONPADRE": 10,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 12,
            "NOMOPCION": "Asignar Tecnico",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/consulta",
            "DSCVISTA": "Consulta",
            "DSCICONO": "person",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 14,
                "NOMOPCION": "Domicilio",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos Domicilio",
                "DSCTITOPCION": "Diagnostico Artefacto",
                "DSCRUTA": "/consulta/diagnosticodomicilio",
                "DSCVISTA": "TecnicoDiagnostico",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 13,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
                "DSCTITOPCION": "Estado de Artefactos",
                "DSCRUTA": "/consulta/artefactos",
                "DSCVISTA": "ResumenBaja",
                "DSCICONO": "notes",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              } 
            ]
          },
          {
            "IDEOPCION": 14,
            "NOMOPCION": "Seguimiento",
            "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
            "DSCTITOPCION": "Resumen Díario de artefactos",
            "DSCRUTA": "/consulta/resumen",
            "DSCVISTA": "ReporteDocumento",
            "DSCICONO": "notes",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
              "IDEOPCION": 22,
              "NOMOPCION": "Domicilio",
              "DSCOPCION": "Consultar Todas las fichas",
              "DSCTITOPCION": "Seguimiento Domicilio",
              "DSCRUTA": "/seguimiento/domicilio",
              "DSCVISTA": "RutaDia",
              "DSCICONO": "house",
              "IDEOPCIONPADRE": null,
              "STSSEGOPCION": 1,
              "NUMORDEN": 3,
              "CHILDREN": [
              ]
              },{
              "IDEOPCION": 17,
              "NOMOPCION": "Taller",
              "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
              "DSCTITOPCION": "Resumen Díario de artefactos",
              "DSCRUTA": "/consulta/resumen",
              "DSCVISTA": "ReporteDocumento",
              "DSCICONO": "notes",
              "IDEOPCIONPADRE": 14,
              "STSSEGOPCION": 1,
              "NUMORDEN": 1,
              "CHILDREN": []
            }]
          },
          {
            "IDEOPCION": 22,
            "NOMOPCION": "Programación",
            "DSCOPCION": "Opción padre para programacion",
            "DSCTITOPCION": "Programacion",
            "DSCRUTA": "/programacion",
            "DSCVISTA": "programacion",
            "DSCICONO": "update",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 24,
                "NOMOPCION": "A Domicilio",
                "DSCOPCION": "Programacion a domicilio",
                "DSCTITOPCION": "Programacion a domicilio",
                "DSCRUTA": "/programacion/ruta",
                "DSCVISTA": "Ruta",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 22,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 15,
            "NOMOPCION": "Reportes",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/reporte",
            "DSCVISTA": "Consulta",
            "DSCICONO": "book",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 16,
                "NOMOPCION": "General",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                "DSCTITOPCION": "RESUMEN DE ARTEFACTOS MENSUAL (30 DIAS ATRAS)",
                "DSCRUTA": "/reporte/taller",
                "DSCVISTA": "Dashboard",
                "DSCICONO": "CC",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 17,
                "NOMOPCION": "Por Revisar Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER",
                "DSCRUTA": "/consulta/porrevisar",
                "DSCVISTA": "ReporteRevisar",
                "DSCICONO": "RT",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              },
              {
                  "IDEOPCION": 17,
                  "NOMOPCION": "Por Reparar Taller",
                  "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                  "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER POR REPARAR",
                  "DSCRUTA": "/consulta/porreparar/9",
                  "DSCVISTA": "ReporteReparar",
                  "DSCICONO": "RR",
                  "IDEOPCIONPADRE": 12,
                  "STSSEGOPCION": 1,
                  "NUMORDEN": 3,
                  "CHILDREN": []
                }
            ]
          },
          {
            "IDEOPCION": 18,
            "NOMOPCION": "Almacén",
            "DSCOPCION": "Opción para almacen",
            "DSCTITOPCION": "Mantenimiento almacen",
            "DSCRUTA": "/almacen",
            "DSCVISTA": "Mantenimiento",
            "DSCICONO": "settings",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 25,
                "NOMOPCION": "Repuestos",
                "DSCOPCION": "Opción para mantenimiento de repuestos",
                "DSCTITOPCION": "ALMACÉN DE REPUESTOS",
                "DSCRUTA": "/almacen/repuesto",
                "DSCVISTA": "AlmacenRepuesto",
                "DSCICONO": "RE",
                "IDEOPCIONPADRE": 18,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 23,
            "NOMOPCION": "Gastos",
            "DSCOPCION": "Opción Gastos Generales",
            "DSCTITOPCION": "Gastos",
            "DSCRUTA": "/gastos",
            "DSCVISTA": "Pasaje",
            "DSCICONO": "paid",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 4,
            "CHILDREN": [
              {
                "IDEOPCION": 24,
                "NOMOPCION": "Pasajes",
                "DSCOPCION": "Opción para ver los pasajes por ficha",
                "DSCTITOPCION": "RESUMEN DE PASAJES POR DÍA",
                "DSCRUTA": "/gastos/pasaje",
                "DSCVISTA": "Pasaje",
                "DSCICONO": "PS",
                "IDEOPCIONPADRE": 23,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              } 
            ]
          }
        ]
      }


      let responseTecnico = {
        "data": [
            {
            "IDEOPCION": 12,
            "NOMOPCION": "Diagnostico",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/consulta",
            "DSCVISTA": "Consulta",
            "DSCICONO": "person",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 2,
            "CHILDREN": [
              {
                "IDEOPCION": 13,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
                "DSCTITOPCION": "Estado de Artefactos",
                "DSCRUTA": "/consulta/artefactos",
                "DSCVISTA": "ResumenBaja",
                "DSCICONO": "CC",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 14,
                "NOMOPCION": "Domicilio",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos Domicilio",
                "DSCTITOPCION": "Diagnostico Artefacto",
                "DSCRUTA": "/consulta/diagnosticodomicilio",
                "DSCVISTA": "TecnicoDiagnostico",
                "DSCICONO": "DD",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              } 
            ]
          },
          {
            "IDEOPCION": 22,
            "NOMOPCION": "Ruta del día",
            "DSCOPCION": "Consultar Todas las fichas",
            "DSCTITOPCION": "Ruta del Día",
            "DSCRUTA": "/programacion/programaciondia",
            "DSCVISTA": "RutaDia",
            "DSCICONO": "RD",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 3,
            "CHILDREN": [
            ]
          },
          {
            "IDEOPCION": 15,
            "NOMOPCION": "Reportes",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/reporte",
            "DSCVISTA": "Consulta",
            "DSCICONO": "book",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 4,
            "CHILDREN": [
              {
                "IDEOPCION": 17,
                "NOMOPCION": "Por Revisar Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER",
                "DSCRUTA": "/consulta/porrevisar",
                "DSCVISTA": "ReporteRevisar",
                "DSCICONO": "RT",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },
              {
                  "IDEOPCION": 18,
                  "NOMOPCION": "Por Reparar Taller",
                  "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                  "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER POR REPARAR",
                  "DSCRUTA": "/consulta/porreparar/9",
                  "DSCVISTA": "ReporteReparar",
                  "DSCICONO": "RR",
                  "IDEOPCIONPADRE": 12,
                  "STSSEGOPCION": 1,
                  "NUMORDEN": 2,
                  "CHILDREN": []
                }
            ]
          },
          {
            "IDEOPCION": 23,
            "NOMOPCION": "Gastos",
            "DSCOPCION": "Opción Gastos Generales",
            "DSCTITOPCION": "Gastos",
            "DSCRUTA": "/gastos",
            "DSCVISTA": "Pasaje",
            "DSCICONO": "paid",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 4,
            "CHILDREN": [
              {
                "IDEOPCION": 24,
                "NOMOPCION": "Pasajes",
                "DSCOPCION": "Opción para ver los pasajes por ficha",
                "DSCTITOPCION": "RESUMEN DE PASAJES POR DÍA",
                "DSCRUTA": "/gastos/pasaje",
                "DSCVISTA": "Pasaje",
                "DSCICONO": "PS",
                "IDEOPCIONPADRE": 23,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              } 
            ]
          }
        ]
      };

      let responseCoordinadora = {
        "data": [
            {
            "IDEOPCION": 10,
            "NOMOPCION": "Nuevo Servicio",
            "DSCOPCION": "Opción padre para Registros",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/registro",
            "DSCVISTA": "Consulta",
            "DSCICONO": "business_center",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 11,
                "NOMOPCION": "A Domicilio",
                "DSCOPCION": "Opción para realizar registro de artefactos",
                "DSCTITOPCION": "Registro de Artefactos de Domicilio",
                "DSCRUTA": "/registro/domicilio",
                "DSCVISTA": "RegistroDomicilio",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 10,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 17,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar registro de artefactos",
                "DSCTITOPCION": "Registro de Artefactos de Taller",
                "DSCRUTA": "/documento/registro",
                "DSCVISTA": "ConsultarDocumento",
                "DSCICONO": "notes",
                "IDEOPCIONPADRE": 10,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 12,
            "NOMOPCION": "Asignar Tecnico",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/consulta",
            "DSCVISTA": "Consulta",
            "DSCICONO": "person",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 14,
                "NOMOPCION": "Domicilio",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos Domicilio",
                "DSCTITOPCION": "Diagnostico Artefacto",
                "DSCRUTA": "/consulta/diagnosticodomicilio",
                "DSCVISTA": "TecnicoDiagnostico",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              },
              {
                "IDEOPCION": 13,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
                "DSCTITOPCION": "Estado de Artefactos",
                "DSCRUTA": "/consulta/artefactos",
                "DSCVISTA": "ResumenBaja",
                "DSCICONO": "notes",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              } 
            ]
          },
          {
            "IDEOPCION": 14,
            "NOMOPCION": "Seguimiento",
            "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
            "DSCTITOPCION": "Resumen Díario de artefactos",
            "DSCRUTA": "/consulta/resumen",
            "DSCVISTA": "ReporteDocumento",
            "DSCICONO": "notes",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
              "IDEOPCION": 22,
              "NOMOPCION": "Domicilio",
              "DSCOPCION": "Consultar Todas las fichas",
              "DSCTITOPCION": "Seguimiento Domicilio",
              "DSCRUTA": "/seguimiento/domicilio",
              "DSCVISTA": "RutaDia",
              "DSCICONO": "house",
              "IDEOPCIONPADRE": null,
              "STSSEGOPCION": 1,
              "NUMORDEN": 3,
              "CHILDREN": [
              ]
              },{
              "IDEOPCION": 17,
              "NOMOPCION": "Taller",
              "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
              "DSCTITOPCION": "Resumen Díario de artefactos",
              "DSCRUTA": "/consulta/resumen",
              "DSCVISTA": "ReporteDocumento",
              "DSCICONO": "notes",
              "IDEOPCIONPADRE": 14,
              "STSSEGOPCION": 1,
              "NUMORDEN": 1,
              "CHILDREN": []
            }]
          },
          {
            "IDEOPCION": 22,
            "NOMOPCION": "Programación",
            "DSCOPCION": "Opción padre para programacion",
            "DSCTITOPCION": "Programacion",
            "DSCRUTA": "/programacion",
            "DSCVISTA": "programacion",
            "DSCICONO": "update",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 24,
                "NOMOPCION": "A Domicilio",
                "DSCOPCION": "Programacion a domicilio",
                "DSCTITOPCION": "Programacion a domicilio ",
                "DSCRUTA": "/programacion/ruta",
                "DSCVISTA": "Ruta",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 22,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 15,
            "NOMOPCION": "Reportes",
            "DSCOPCION": "Opción padre para Consultas",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/reporte",
            "DSCVISTA": "Consulta",
            "DSCICONO": "book",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 16,
                "NOMOPCION": "General",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                "DSCTITOPCION": "RESUMEN DE ARTEFACTOS MENSUAL (30 DIAS ATRAS)",
                "DSCRUTA": "/reporte/taller",
                "DSCVISTA": "Dashboard",
                "DSCICONO": "CC",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              },{
                "IDEOPCION": 17,
                "NOMOPCION": "Por Revisar Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER",
                "DSCRUTA": "/consulta/porrevisar",
                "DSCVISTA": "ReporteRevisar",
                "DSCICONO": "RT",
                "IDEOPCIONPADRE": 12,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              },
              {
                  "IDEOPCION": 18,
                  "NOMOPCION": "Por Reparar Taller",
                  "DSCOPCION": "Opción para realizar consulta del estado de artefactos por linea",
                  "DSCTITOPCION": "RESUMEN DE ARTEFACTOS PARA TECNICOS TALLER POR REPARAR",
                  "DSCRUTA": "/consulta/porreparar/9",
                  "DSCVISTA": "ReporteReparar",
                  "DSCICONO": "RR",
                  "IDEOPCIONPADRE": 12,
                  "STSSEGOPCION": 1,
                  "NUMORDEN": 3,
                  "CHILDREN": []
                }
            ]
          },
          ,
          {
            "IDEOPCION": 23,
            "NOMOPCION": "Gastos",
            "DSCOPCION": "Opción Gastos Generales",
            "DSCTITOPCION": "Gastos",
            "DSCRUTA": "/gastos",
            "DSCVISTA": "Pasaje",
            "DSCICONO": "paid",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 4,
            "CHILDREN": [
              {
                "IDEOPCION": 24,
                "NOMOPCION": "Pasajes",
                "DSCOPCION": "Opción para ver los pasajes por ficha",
                "DSCTITOPCION": "RESUMEN DE PASAJES POR DÍA",
                "DSCRUTA": "/gastos/pasaje",
                "DSCVISTA": "Pasaje",
                "DSCICONO": "PS",
                "IDEOPCIONPADRE": 23,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              } 
            ]
          }
        ]
      };

      let responseIlumi = {
        "data": [
            {
            "IDEOPCION": 10,
            "NOMOPCION": "Nuevo Servicio",
            "DSCOPCION": "Opción padre para Registros",
            "DSCTITOPCION": "Cuestionario",
            "DSCRUTA": "/registro",
            "DSCVISTA": "Consulta",
            "DSCICONO": "business_center",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
                "IDEOPCION": 11,
                "NOMOPCION": "A Domicilio",
                "DSCOPCION": "Opción para realizar registro de artefactos",
                "DSCTITOPCION": "Registro de Artefactos de Domicilio",
                "DSCRUTA": "/registro/domicilio",
                "DSCVISTA": "RegistroDomicilio",
                "DSCICONO": "house",
                "IDEOPCIONPADRE": 10,
                "STSSEGOPCION": 1,
                "NUMORDEN": 1,
                "CHILDREN": []
              }
            ]
          },
          {
            "IDEOPCION": 14,
            "NOMOPCION": "Seguimiento",
            "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
            "DSCTITOPCION": "Resumen Díario de artefactos",
            "DSCRUTA": "/consulta/resumen",
            "DSCVISTA": "ReporteDocumento",
            "DSCICONO": "notes",
            "IDEOPCIONPADRE": null,
            "STSSEGOPCION": 1,
            "NUMORDEN": 1,
            "CHILDREN": [
              {
              "IDEOPCION": 22,
              "NOMOPCION": "Domicilio",
              "DSCOPCION": "Consultar Todas las fichas",
              "DSCTITOPCION": "Seguimiento Domicilio",
              "DSCRUTA": "/seguimiento/domicilio",
              "DSCVISTA": "RutaDia",
              "DSCICONO": "house",
              "IDEOPCIONPADRE": 14,
              "STSSEGOPCION": 1,
              "NUMORDEN": 1,
              "CHILDREN": [
              ]
              },{
                "IDEOPCION": 23,
                "NOMOPCION": "Taller",
                "DSCOPCION": "Opción para realizar consulta del estado de artefactos",
                "DSCTITOPCION": "Resumen Díario de artefactos",
                "DSCRUTA": "/consulta/resumen",
                "DSCVISTA": "ReporteDocumento",
                "DSCICONO": "notes",
                "IDEOPCIONPADRE": 14,
                "STSSEGOPCION": 1,
                "NUMORDEN": 2,
                "CHILDREN": []
              }]
          }
        ]
      };
       
      if (localStorage.getItem("tkn") == "serfac-tecnicos"){
        this.setState({ opciones: responseTecnico.data })
      }else if (localStorage.getItem("tkn") == "serfac-coordinadora"){
        this.setState({ opciones: responseCoordinadora.data })
      }else if (localStorage.getItem("tkn") == "serfac-ilumi" || localStorage.getItem("tkn") == "serfac-indurama"){
        this.setState({ opciones: responseIlumi.data })
      }else{
        this.setState({ opciones: response.data })  
      }


      
  
  }
  render() {
    if (this.state.opciones.length <= 0){
         this.buscarOpciones(); 
    }
    const { classes, authenticated, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <Alert message={this.props.messageAlert} color={this.props.colorAlert}
          place={this.props.placeAlert} open={this.props.openAlert} closeNotification={(a, b) => this.closeNotification(a, b)} />
        <Loader loading={this.props.loading} />
        <Sidebar
          routes={this.state.opciones}
          logoText={"SPIRIT"}
          logo={logo}
          logoMini={logoMini}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          handleItemListCollapse={this.handleItemListCollapse}
          mobileOpen={this.state.mobileOpen}
          open={this.state.open}
          states={this.state}
          color="red"
          {...rest}
        />
        <div className={classNames(classes.mainPanel, {
          [classes.mainPanelShift]: !this.state.open,
          [classes.overflowHidden]: !isMobile
        })} ref="mainPanel">
          <Header
            routes={this.state.opciones}
            handleDrawerToggle={this.handleDrawerToggle}
            handleMiniDrawerToggle={this.handleMiniDrawerToggle}
            open={this.state.open}
            mobileOpen={this.state.mobileOpen}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {this.state.opciones.map((prop, key) => {
                    if (prop.redirect) {
                      return <Redirect from={prop.DSCRUTA} to={prop.to} key={prop.IDEOPCION} />;
                    }
                    if (prop.CHILDREN.length > 0) {
                      const hijos = (prop.CHILDREN.map((childProp, childkey) => {
                        return <Route exact path={childProp.DSCRUTA} component={VIEW_COMPONENT[childProp.DSCVISTA]} key={childProp.IDEOPCION} />;
                      }));
                      return hijos;
                    } else {
                      return <Route exact path={prop.DSCRUTA} component={VIEW_COMPONENT[prop.DSCVISTA]} key={prop.IDEOPCION} />;
                    }
                  })}
                  {/* <Redirect push to={authenticated ? PATH_APP.HOME1 : PATH_APP.LOGIN} /> /> */}                
                </Switch>
              </div>
            </div>
          ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>
    );
  }
}
//const { object } = PropTypes;

App.propTypes = {
  //actions: object.isRequired,
  classes: PropTypes.object.isRequired
};

const mapState = (state) => ({
  loading: state.load.loading,
  messageAlert: state.notification.message,
  colorAlert: state.notification.color,
  placeAlert: state.notification.place,
  openAlert: state.notification.open,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    opcionActions: bindActionCreators(opcionActions, dispatch)
  };
};

App = withStyles(homeStyle)(App);
//export default App;
export default withRouter(connect(mapState, mapDispatch)(App));
