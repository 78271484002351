import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect"; 
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import CustomTextArea from "components/CustomInput/CustomTextArea";
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import {  required} from 'utils/FormValidation';  

import { getFormatDate } from 'utils/Functions'; 
import {  DATA_ESTADO_REPUESTO, DATA_ESTADOS_DIALOG_SEGUIMIENTO_DOMICILIO,  DATA_ESTADOS_DIAGNOSTICO_DOMICLIO_TECNICO} from 'utils/GeneralData';
class MntAlmacenRepuestoAddOutForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
         showGuadar: '',
         tipsTrabajo: [],
         documento:  {  
            modelo:'', 
            marca:'',
            producto:'',
            codigo:'',
            imagen: '',
            tipo: '',
            cantidad: '',
            stock: '',  
            precio_soles: 0,
            precio_dolares: 0,
            id: '',  
            blockDisabled: false,
            observacion: '',
            ubicacion: '',
            fecha: '',
            comprobante: '', 
            sede:'',
          },
          tipoIngresoSalida: [  ],  
          errorFecha: '',
          errorFechaFin: '',
          idlocal: 0,
          celular: '',
          tecnicos: [],
      }
      
  }

  componentDidMount() {
      this.props.onRef(this);
      const {   documento } = this.props;
      console.log("datos que llegaron gagagagag" , documento);
      this.props.change('modelo', documento.modelo)
      this.props.change('codigo', documento.codigo) 
      
      this.props.change('hId', documento.id) 
      this.props.change('ubicacion', documento.ubicacion) 

      this.props.change('stock', documento.stock) 
      this.props.change('sede', documento.sede) 
      this.props.change('descripcion', documento.descripcion) 
 
 
 
      this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
      this.state.documento.hFechaInicio = getFormatDate(new Date(), 'ymd', '-') 
      this.props.documento.fecha = getFormatDate(new Date(), 'ymd', '-')
      this.state.documento.fecha = getFormatDate(new Date(), 'ymd', '-') 

      this.state.documento.observacion = documento.observacion;    
      this.state.documento.id = documento.id;  
      this.state.documento.imagen = documento.imagen
      this.state.tipoIngresoSalida =  [{"id":"I","valor":"INGRESO"},{"id":"S","valor":"SALIDA"}];
 

    /*  this.state.documento.estado = {
        "id": "2",
        "valor": "COTIZADO"
      } */
  }

  extraerRows = ( ) => {
    let rowsRepuestos = this.Grid.extraerRows();
    console.log(rowsRepuestos);
    return rowsRepuestos;
  }  


  

  setFechaIni = (event) => {
    let fecha = event.target.value;
    this.setState({ errorFechaFin: false });
    this.setState({ errorFecha: false });
    this.state.documento.fecha = fecha;
    this.props.change('hFechaInicio', fecha) 
  }

  setFechaVenta = (event) => {
    let fecha = event.target.value;
     
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaVenta = fecha;
      this.props.change('hFechaFin', fecha) 
  }


  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogRepuesto, repuestos } = this.props;
    const { documento , estadoArtefactos, monedas, lineas, marcas, areas, artefactos, showGuadar, errorFecha, errorFechaFin} = this.state;

    

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Ingreso/Salida"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        autoComplete: "tipo",
                        name: "tipo"
                      }}
                      multiple={false}
                      data={this.state.tipoIngresoSalida}
                      component={CustomSelect}
                      valor={documento.tipo}
                      getValue={this.getSelectValue}
                      validate={[required]}
                      val="id"
                      txt="valor"
                      name="tipo"
                      id="tipo" />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Cantidad"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "cantidad" 
                  }}      
                  normalize={(val, prevVal) => {
                    if (val.length > 20)
                      return prevVal;
                      if (val) {
                        return (!/[^0-9--_/]/.test(val)) ? val : prevVal
                      }
                    return val;
                  }}              
                  component={CustomInput}
                  value={documento.cantidad}
                  name="cantidad"
                  id="cantidad" />
              </GridItem> 
                
              <GridItem xs={12} sm={12} md={3}>
             
                    <Field labelText="comprobante"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "comprobante",
                          name: "comprobante",
                          min: "1"
                        }}
                        
                        component={CustomInput}
                        value={documento.comprobante}
                        name="comprobante"
                        id="comprobante" /> 
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Observación"
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "observacion",
                              name: "observacion",
                              rows: 9,
                              min: "1"
                            }} 
                            component={CustomInput}
                            value={documento.observacion}
                            name="observacion"
                            id="observacion" /> 
                </GridItem>
                
                  
                  
              </GridContainer>
          </GridItem>
 
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                    <CustomInput labelText="Fecha"
                                    formControlProps={{
                                      fullWidth: true,
                                      margin: "normal",
                                      style: { marginTop: "0px" }
                                    }}
                                    inputProps={{
                                      autoComplete: "fecha",
                                      name: "fecha",
                                      type: "date",
                                      //defaultValue: fecdefault,
                                      onChange: this.setFechaIni,
                                      value:  documento.fecha,
                                      format: 'DD-MM-YYYY'
                                    }}
                                  // validate={[ required ]}
                                    meta={{ touched: true, error: errorFecha, warning: false }}
                                    //value={documento.docuFechaIni}
                                    //name="docuFechaIni"
                                  // id="docuFechaIni"
                                    />
                                    <Field
                          formControlProps={{
                            fullWidth: false,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "hidden",
                            name: "hFechaInicio",
                            min: "1"
                          }}
                          component={CustomInput}
                          value={documento.docuFechaIni}
                          name="hFechaInicio"
                          id="hFechaInicio" />  
                  </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                    
                    <Field labelText="Stock Actual"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          type: "stock",
                          name: "stock",
                          min: "1",
                          disabled: true
                        }}
                        
                        component={CustomInput}
                        value={documento.stock}
                        name="stock"
                        id="stock" /> 
                </GridItem>
              
              <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Codigo"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "codigo",
                        name: "codigo",
                        min: "1",
                        disabled: true
                      }}
                      component={CustomInput}
                      validate={[required]}
                      value={documento.codigo}
                      name="codigo"
                      id="codigo" /> 

              <Field
                    formControlProps={{
                      fullWidth: false,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "hidden",
                      name: "hId",
                      min: "1"
                    }}
                    component={CustomInput}
                    value={documento.id}
                    name="hId"
                    id="hId" />

                  </GridItem> 
              <GridItem xs={12} sm={12} md={3}>
                      <Field labelText="Modelo"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          name: "modelo",
                          disabled: true
                        }}
                        
                        component={CustomInput}
                        value={documento.modelo} 
                        name="modelo"
                        id="modelo" />
                  </GridItem>    
                  
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                      
                      <Field labelText="sede"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "sede",
                            name: "sede",
                            min: "1",
                            disabled: true
                          }}
                          
                          component={CustomInput}
                          value={documento.sede}
                          name="sede"
                          id="sede" /> 
                </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                      
                      <Field labelText="Descripción"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "descripcion",
                            name: "descripcion",
                            min: "1",
                            disabled: true
                          }}
                          
                          component={CustomInput}
                          value={documento.descripcion}
                          name="descripcion"
                          id="descripcion" /> 
                </GridItem>
              
            </GridContainer>
          </GridItem>
          


 
          
        </GridContainer> 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
               
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ display: showGuadar }}
                className={classes.submit}
              >Guardar</Button>
               
              <Button onClick={() => toggleDialogRepuesto()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      
        <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                  <img src={documento.imagen}   />
                </GridItem>
 
              
            </GridContainer>
          </GridItem>
      </form>
    );
  }
}

MntAlmacenRepuestoAddOutForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntAlmacenRepuestoAddOutForm = reduxForm({
  form: 'mntAlmacenRepuestoAddOutForm',
  enableReinitialize: true
})(MntAlmacenRepuestoAddOutForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntAlmacenRepuestoAddOutForm));
